import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import Graficos from './Graficos';
import './css/dashboard.css';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: "0px 0x 0px 0px gray",
    borderRadius: "0px",
    height: "100%"
  },
  colorPaper: {
    backgroundColor: "#f9f9f9",
  }
}));



export default function Dashboard() {
  const classes = useStyles();

  return (
    <div className="Dashboard-container">
      <Grid container>
      <Grid item xs={12}>
        <Paper className={clsx(classes.paper, classes.colorPaper)}>
          <Graficos/>
        </Paper>
      </Grid>
    </Grid>
  </div>
  );
}